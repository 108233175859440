import React from 'react'
import ExclamationTriangleIcon from '../elements/icons/ExclamationTriangleIcon'

const TaskCard = ({task}) => {
  return (
    <div className='w-full flex items-start justify-between gap-x-[20px] py-[10px] border-b border-white border-opacity-30'>
        <div className='w-full'>
            <h3 className='font-[500] font-space-grotesk text-white text-[15px] leading-[18px] mb-[5px]'>{task?.task}</h3>
            <p className='font-urbanist text-[14px] text-white font-medium leading-[18px]'>Due date: {task.dueDate}</p>

            <div className='flex items-center gap-x-[10px] mt-[10px]'>
                <div className='w-[32px]'>
                    <div className='w-[30px] h-[30px] rounded-full' style={{
                        backgroundImage: `url(${task?.assignee?.avatar})`, 
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center'
                    }} />
                </div>
                <p className='font-urbanist text-[14px] text-white font-medium leading-[18px]'>{task.assignee?.name}</p>
            </div>
        </div>
        <div className='w-[100px]'>
            <div className='flex items-center justify-between mb-[10px] border-gray-300'>
                {task.overdue && <button className='bg-red-500 text-white text-[14px] flex items-center gap-x-[5px] rounded-full px-[8px] py-[4px]'><ExclamationTriangleIcon className={`w-4 h-4`} /> Overdue</button>}
            </div>
        </div>
    </div>
  )
}

export default TaskCard