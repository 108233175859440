import React from 'react'
import BellIcon from '../elements/icons/BellIcon'

const CaregiverOnCallCard = ({caregiver}) => {
  return (
    <div className='w-full flex items-start justify-between gap-x-[20px] border-b pb-[10px]'>
        <div className='w-[52px]'>
            <div className='w-[50px] h-[50px] rounded-full border-2 border-doiteasy-light-gray' style={{
                backgroundImage: `url(${caregiver?.avatar})`, 
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
            }} />
        </div>
        <div className='w-full'>
            <div className='flex items-center justify-between'>
                <h3 className='font-[500] font-space-grotesk text-doiteasy-black text-[15px] leading-[18px] mb-[5px]'>{caregiver?.name}</h3>
                <p className='font-urbanist text-[14px] text-doiteasy-black font-medium leading-[18px]'>{caregiver?.shiftTime}</p>
            </div>
            <div className='flex items-center justify-between mb-[10px] border-gray-300'>
                <p className='font-urbanist text-[14px] text-[#828282] leading-[18px]'>{caregiver?.department}</p>
                <button className='text-red-500 text-[14px] font-medium flex items-center gap-x-[5px]'><BellIcon className={`w-4 h-4`} /> Ping</button>
            </div>
        </div>
        {/* <div>
        </div> */}
    </div>
  )
}

export default CaregiverOnCallCard