import { ADMISSIONS_ERROR, CREATE_ADMISSION, CREATING_ADMISSION, GETTING_ADMISSIONS, GET_ADMISSIONS, UPDATE_ADMISSION, UPDATING_ADMISSION } from "../types"

const initialState = {
    admissions: [],
    loadingAdmissions: true,
    creatingAdmission: false,
    createdAdmission: null,
    updatingAdmission: false,
    updatedAdmission: null,
    admissionsError: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_ADMISSIONS:
        return {
            ...state,
            loadingAdmissions :action.payload,
        }
        case GET_ADMISSIONS:
        return{
            ...state,
            loadingAdmissions:false,
            admissionsError:  null,
            admissions: action.payload,
        }
        case CREATING_ADMISSION:
        return {
            ...state,
            creatingAdmission :action.payload,
        }
        case CREATE_ADMISSION:
        return{
            ...state,
            creatingAdmission:false,
            admissionsError:  null,
            createdAdmission: action.payload,
        }
        case UPDATING_ADMISSION:
        return {
            ...state,
            updatingAdmission :action.payload,
        }
        case UPDATE_ADMISSION:
        return{
            ...state,
            updatingAdmission:false,
            admissionsError:  null,
            updatedAdmission: action.payload,
        }
        case ADMISSIONS_ERROR:
        return{
            ...state,
            loadingAdmissions:false,
            creatingAdmission: false,
            updatingAdmission: false,
            admissionsError: action.payload 
        }
        default: return state
    }

}