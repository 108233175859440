import './App.css';
import React from "react";
import "./index.css"

import {
  Routes,
  Route,
  Navigate
  // Link,
  // useParams,
  // useRouteMatch
} from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import Admin from "./pages/Admin";
import ErrorPage from "./pages/ErrorPage";
import AdminErrorPage from './pages/admin/AdminErrorPage';
import ActivateAccount from './pages/ActivateAccount';
import PasswordReset from './pages/PasswordReset';
// import UserProfile from './pages/admin/settings/UserProfile';
// import Security from './pages/admin/settings/Security';
import ScrollToTop from './components/layouts/ScrollToTop';
import { Provider } from 'react-redux';
import store from './store/store';
import ErrorNotifier from './components/elements/ErrorNotifier';
import SuccessNotifier from './components/elements/SuccessNotifier';
import Signup from './pages/Signup';
import Overview from './pages/admin/Overview';


export default function App() {
  return (
    <main>
    <Provider store={store}>
      <ErrorNotifier />
      <SuccessNotifier />
      <ScrollToTop>


      <ScrollToTop>

          <Routes>
            <Route path="/" exact element={<LoginPage />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/password-reset" exact element={<PasswordReset />} />
            <Route path="/activate-account/:activationCode" exact element={<ActivateAccount />} />
            <Route path="/staff-onboarding/:staffId/" exact element={<ActivateAccount />} />
            <Route path="/admin" element={<Admin />}>
              <Route path="/admin/" element={<Navigate replace to="/admin/dashboard/overview" />} />
              <Route path="/admin/dashboard/overview" element={<Overview />} />
              
              {/* <Route path="/admin/parents" element={<Parents />} />
              <Route path="/admin/parents/parent-profile/:parentId" element={<Parent />} />
              
              <Route path="/admin/students" element={<Students />} />
              <Route path="/admin/students/student/:studentId" element={<Student />} />

              <Route path="/admin/college-staff" element={<CollegeStaff />} />
              <Route path="/admin/college-staff/onboard-new-staff" element={<OnboardStaff />} />
              <Route path="/admin/college-staff/:staffId" element={<CollegeStaffProfile />} />

              <Route path="/admin/administration" element={<Administration />}>
                <Route path="/admin/administration/" element={<Navigate replace to="/admin/administration/dashboard" />} />
                <Route path="/admin/administration/dashboard" element={<AdministrationDashboard />} />
                <Route path="/admin/administration/academic-sessions" element={<AcademicSessions />} />
                <Route path="/admin/administration/admissions" element={<Admissions />} />
                <Route path="/admin/administration/admissions/:admissionId" element={<Admission />} />
                <Route path="/admin/administration/applications" element={<Applications />} />
                <Route path="/admin/administration/applications/analytics" element={<ApplicationAnalytics />} />
                <Route path="/admin/administration/applications/:applicationId" element={<Application />} />
                <Route path="/admin/administration/examinations" element={<Examinations />} />
                <Route path="/admin/administration/classes" element={<ClassesDivisions />} />
                <Route path="/admin/administration/classes/:classId" element={<ClassDetails />} />
                <Route path="/admin/administration/departments" element={<Departments />} />
                <Route path="/admin/administration/designations" element={<StaffDesignations />} />
                <Route path="/admin/administration/documents" element={<Documents />} />
                <Route path="/admin/administration/documents/details/:documentId" element={<Document />} />
                <Route path="/admin/administration/documents/:documentId" element={<NewDocument />} />
              </Route> 

              <Route path="/admin/academics" element={<Academics />}>
                <Route path="/admin/academics/" element={<Navigate replace to="/admin/academics/dashboard" />} />
                <Route path="/admin/academics/dashboard" element={<AcademicsDashboard />} />
                <Route path="/admin/academics/subjects" element={<Subjects />} />
                <Route path="/admin/academics/timetables" element={<TimeTables />} />
                <Route path="/admin/academics/assessments" element={<Assessments />} />
              </Route> 

              <Route path="/admin/accounting" element={<Accounting />}>
                <Route path="/admin/accounting/" element={<Navigate replace to="/admin/accounting/dashboard" />} />
                <Route path="/admin/accounting/dashboard" element={<AccountingDashboard />} />
                <Route path="/admin/accounting/fees" element={<Fees />} />
                <Route path="/admin/accounting/invoices" element={<Invoices />} />
                <Route path="/admin/accounting/payments" element={<Payments />} />
                <Route path="/admin/accounting/sundry-and-advances" element={<Payments />} />
              </Route> 

              <Route path="/admin/settings" element={<Settings />}>
                <Route path="/admin/settings/" element={<Navigate replace to="/admin/settings/user-profile" />} />
                <Route path="/admin/settings/user-profile" element={<UserProfile />} />

                <Route path="/admin/settings/security" element={<Security />} />

                <Route path="/admin/settings/audit-logs" element={<AuditLogs />} />

                <Route path="/admin/settings/system-users" element={<SystemUsers />} />
                
                <Route path="/admin/settings/roles-permissions" element={<Roles />} />
                <Route path="/admin/settings/roles-permissions/role/:roleId" element={<RoleDetails />} />
                <Route path="/admin/settings/roles-permissions/new-role" element={<NewRole />} />
                
                <Route path="/admin/settings/users" element={<Users />} />
              </Route> 

              <Route path="/admin/inventory" element={<Inventory />}>
                <Route path="/admin/inventory/" element={<Navigate replace to="/admin/inventory/dashboard" />} />
                <Route path="/admin/inventory/dashboard" element={<InventoryDashboard />} />
                <Route path="/admin/inventory/requests" element={<Requests />} />
                <Route path="/admin/inventory/library" element={<LibraryStock />} />
                <Route path="/admin/inventory/sports-equipment" element={<SportsEquipment />} />
                <Route path="/admin/inventory/kitchen-stock" element={<KitchenStock />} />
              </Route>  */}

              <Route path="/admin/*" element={<AdminErrorPage />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ScrollToTop>

      </ScrollToTop>
    </Provider>
    </main>
  );
}
