import React, { useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import DefaultCareHomeLogo from '../../assets/images/icons/care-home-icon.svg'
import { slugify } from '../../utils';
import { useOutsideAlerter } from '../elements/form/SelectField';

const CollapsedSidebar = ({sections, careHome}) => {
    const location = useLocation();
    const currentRoute = location.pathname;
    const [activeSection, setActiveSection] = useState('')
    const navigate = useNavigate()

    const toggleLink = (sectionTitle) => {
        if(activeSection === slugify(sectionTitle)) {
            setActiveSection('')
            return
        }
        setActiveSection(slugify(sectionTitle))
    }

    const navigateToRoute = (route) => {
        navigate(route)
        setActiveSection('')
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, ()=>setActiveSection(''));

    return (
        <div className="relative w-full min-h-screen bg-white px-4 flex flex-col items-center pt-12">
            {/* <div className="pt-3 w-full flex items-center justify-center mb-12">
                <img src={VcmLogo} alt="" className="w-3/5" />
            </div> */}

            <div className='rounded-[8px] mt-[10px] border-gray-300 flex items-center justify-center gap-x-[6px] mb-[24px]'>
                <img src={DefaultCareHomeLogo} alt='' className='w-[30px]' />
                {/* <div className='w-full'>
                    <h3 className='font-space-grotesk text-doiteasy-black'>{careHome.careHome.name}</h3>
                    <p className='text-xs text-[#8D9696]'>{careHome.careHome.contact.email}</p>
                </div> */}
            </div>
            {sections.map((section, sectionIndex) => (
                <span key={sectionIndex}>
                    {section.title === '' ? section.links.map((link, linkIndex)=>(
                        <NavLink 
                            key={linkIndex} 
                            to={link.route} 
                            className={`${currentRoute.includes(link.activeCheck) ? 'text-white bg-doiteasy-blue' : 'text-doiteasy-black'} text-sm flex items-center justify-center rounded-full w-[30px] h-[30px] font-[550] mb-[20px]`}
                        >
                            <img src={currentRoute.includes(link.activeCheck) ? link.activeIcon : link.icon} alt='' className='w-[20px]' />
                        </NavLink>
                    )) :  
                    <div className='relative'>
                        <button onClick={()=>{toggleLink(section.title)}} className={`w-[30px] flex items-center mb-[20px] rounded-full h-[30px] justify-center ${currentRoute.includes(section.activeCheck) ? 'bg-doiteasy-blue' : ''}`}>
                            <img src={currentRoute.includes(section.activeCheck) ? section.activeIcon : section.icon} alt='' className='w-[20px]' />
                        </button>
                        {/* {section.title && section.title !== '' && <h4 className='text-doiteasy-black font-[550] font-space-grotesk ml-[15px]'>{section.title}</h4>} */}
                        {activeSection === slugify(section.title) && <div ref={wrapperRef} className='absolute left-[60px] top-0 px-[10px] py-[20px] shadow-2xl rounded-[8px] bg-white w-[250px]'>
                            {section.title && section.title !== '' && <h4 className='text-doiteasy-black font-[550] font-space-grotesk mb-[15px]'>{section.title}</h4>}
                            {section.links.map((link, linkIndex)=>(
                                <button 
                                    key={linkIndex} 
                                    onClick={()=>navigateToRoute(link.route)} 
                                    className={`${currentRoute.includes(link.activeCheck) ? 'text-white bg-doiteasy-blue' : 'text-doiteasy-black'} text-sm flex px-[16px] py-[10px] rounded-[8px] w-full flex-row gap-x-1 items-center font-[550]`}
                                >
                                    {/* <div className="w-[20px] flex items-center justify-center">
                                        <img src={currentRoute.includes(link.activeCheck) ? link.activeIcon : link.icon} alt='' />
                                    </div> */}
                                    {link.title}
                                </button>
                            ))}
                        </div>}
                    </div>}
                </span>
            ))}
            
        </div>
        
    )
}

export default CollapsedSidebar
