import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DoiteasyLogo from '../components/elements/DoiteasyLogo'
import TextField from '../components/elements/form/TextField'
import { ERROR } from '../store/types'
import { useDispatch } from 'react-redux'
import FormButton from '../components/elements/form/FormButton'
import axios from 'axios'
import OTPInput from '../components/elements/form/OtpInput'
import Countdown from '../components/elements/Countdown'
import PasswordField from '../components/elements/form/PasswordField'

const PasswordReset = () => {
    const dispatch = useDispatch()
    const [validationErrors, setValidationErrors] = useState({});
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [counted, setCounted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [password, setPassword] = useState('');

    const validateForm = () => {
        let errors = {}
        if(!email || email === '') {
            errors.email = true
        }
        setValidationErrors(errors)

        return errors
    }

    const requestOtp = async  () => {
        const headers = {
            'Content-Type': 'application/json'
        }
        
        if (Object.values(validateForm()).includes(true)) {

            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        setProcessing(true)

        const requestPayload = { email }
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/sessions`, requestPayload, { headers })            
            setOtpSent(true)
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }


    const resendOtp = async (resending) => {
        try {
            const headers = {
                'Content-Type': 'application/json'
            }
            const requestPayload = { 
                email: email,
            }
            
            await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/auth/resend-confirmation/email`, requestPayload, { headers }) 
            setProcessing(false)
            setOtpSent(true)
            setCounted(false)
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }

    const [otpSent, setOtpSent] = useState(false)

    const validatePasswordForm = () => {
        let errors = {}
        if(!otp || otp === '') {
            errors.otp = true
        }
        if(!password || password === '') {
            errors.password = true
        }
        setValidationErrors(errors)

        return errors
    }

    const createPassword = async (resending) => {
        if (Object.values(validatePasswordForm()).includes(true)) {

            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }
    
        try {
            const headers = {
                'Content-Type': 'application/json'
            }
            const requestPayload = { 
                email: email,
            }
            
            await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/auth/resend-confirmation/email`, requestPayload, { headers }) 
            setProcessing(false)
            setOtpSent(true)
            setCounted(false)
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }
    return (
        <div className="w-full min-h-screen flex pt-[20px] justify-center bg-doiteasy-light-gray">
            <div className="w-5/12">
                <DoiteasyLogo />

                <h3 className="font-medium mt-[10px] text-secondary text-lg mb-1 text-center">Reset your password</h3>
                <p className="text-sm text-opacity-80 text-center">Please provide your registered email to request a password reset</p>

                <div className='rounded-[32px] bg-white px-[40px] py-[60px] mt-8'>
                    <div className='mt-4'>
                        <TextField
                            inputLabel="Email address" 
                            fieldId="username" 
                            inputType="text" 
                            preloadValue={''}
                            inputPlaceholder={'Your registered email address'}
                            hasError={validationErrors && validationErrors.username} 
                            returnFieldValue={(value)=>{setEmail(value)}}
                        />

                        <label className='block mt-2 text-sm text-gray-400'>We will send an otp to your email.</label>
                    </div>
                    {!otpSent && <>
                        <div className='mt-8 w-[60%] mx-auto'>
                            <FormButton buttonLabel="Request Password Reset" buttonAction={()=>{requestOtp()}} processing={processing} />
                        </div>
                    </>}

                    {otpSent && <>
                        <p className="text-sm text-doiteasy-black text-center mb-6 mt-8">Please provide the otp sent to your email along with your new password to complete the reset</p>
                        <div className='w-max mx-auto'>
                            <OTPInput length={6} onChange={(value)=>{setOtp(value)}} hasError={validationErrors && validationErrors.otp} />
                        </div>
                        {counted 
                            ? 
                            <button 
                                className='bg-red-400 p-2 rounded-md mt-3 font-medium w-full block bg-opacity-10 text-red-400 transition duration-200 hover:bg-opacity-20' 
                                onClick={()=>{resendOtp()}}
                            >
                                Resend confirmation email
                            </button>
                            :
                            <>
                                <p className='py-5 mt-2 text-gray-400 text-center'>Didn&apos;t get the otp? please wait <Countdown seconds={60} className='inline text-blue-800 font-bold' countdownComplete={()=>{setCounted(true)}} /> seconds
                                </p>
                            </>
                        }

                        {otp.length === 6 && <>
                            <div className='mt-4'>
                                <PasswordField
                                    inputLabel="New Password" 
                                    fieldId="password" 
                                    inputType="password" 
                                    inputPlaceholder={'Your password'}
                                    preloadValue={''}
                                    hasError={validationErrors && validationErrors.password} 
                                    returnFieldValue={(value)=>{setPassword(value)}}
                                />
                            </div>

                            <div className='mt-8 w-[60%] mx-auto'>
                                <FormButton buttonLabel="Create new password" buttonAction={()=>{createPassword()}} processing={processing} />
                            </div>
                        </>}
                        
                    </>}
                    <div className='w-full text-center mt-8'>
                        <p className="my-3 text-sm block">
                            <Link to="/" className="text-secondary">Back to login page</Link>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PasswordReset
