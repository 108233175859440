import React from 'react'
import { Link } from 'react-router-dom'
// import PayafrikLogo from '../assets/images/pfk-logo-w.svg'

const ActivateAccount = () => {
    return (
        // <div className="flex flex-row bg-primary w-full">
            <div className="w-full min-h-screen flex items-center justify-center">
                <div className="w-3/12">
                    <h1 className="text-4xl text-primary font-bold mb-5 text-opacity-80">judiciary.</h1>

                    <h3 className="font-medium text-accent text-lg mb-4">Welcome</h3>
                    <p className="text-sm">you're here because you were invited to use the Payafrik Smart Power Administrator platform. <br />please create your password to activate your account</p>

                    <div className='bg-gradient-to-r from-primary via-accent to-primary h-px w-full my-5' />

                    <label className="mt-6 mb-3 text-xs block">Password</label>
                    <input type="password" placeholder="Your password" className="w-full px-4 py-3 rounded border border-gray-300 focus:border-gray-500 text-xs focus:outline-none transition duration-200" />

                    <label className="mt-6 mb-3 text-xs block">Re-type Password</label>
                    <input type="password" placeholder="Confirm your password" className="w-full px-4 py-3 rounded border border-gray-300 focus:border-gray-500 text-xs focus:outline-none transition duration-200" />

                    <p className="my-3 text-xs block">Already activated? <Link to="/" className="text-secondary">Click here to log in</Link></p>
                    <button className="w-full px-4 py-4 rounded-lg bg-primary text-xs text-white font-medium transition duration-200 mt-4 hover:bg-opacity-80">Activate my account</button>
                </div>
            </div>
        // </div>
    )
}

export default ActivateAccount
