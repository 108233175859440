import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"

// import ErrorMessage from '../components/elements/ErrorMessage'
import TextField from '../components/elements/form/TextField';
import PasswordField from '../components/elements/form/PasswordField';
import FormButton from '../components/elements/form/FormButton';
import { useDispatch } from 'react-redux';
import { ERROR } from '../store/types';
import UnderlineVector from '../assets/images/underline.svg'
import DoiteasyLogo from '../components/elements/DoiteasyLogo';
import ChevronIcon from '../components/elements/icons/ChevronIcon';
import CloseIcon from '../components/elements/icons/CloseIcon';
import ModalDialog from '../components/layouts/ModalDialog';
import EmailConfirmation from '../components/partials/EmailConfirmation';
import DashMockup from '../assets/images/doiteasy-dashboard-cropped.png'

const LoginPage = () => {
    let navigate = useNavigate()   
    const dispatch = useDispatch() 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [processing, setProcessing] = useState(false)
    // const [error, setError] = useState('')  

    const [validationErrors, setValidationErrors] = useState({})  

    const validateForm = () => {
        let errors = {}
        if(!email || email === '') {
            errors.email = true
        }
        if(!password || password === '') {
            errors.password = true
        }
        setValidationErrors(errors)

        return errors
    }
    
    const logIn = async  () => {
        const headers = {
            'Content-Type': 'application/json'
        }
        
        if (Object.values(validateForm()).includes(true)) {

            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        setProcessing(true)

        const requestPayload = { email, password }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/sessions`, requestPayload, { headers })            

            localStorage.setItem("authToken", JSON.stringify(response.data.data.accessToken));
            getUserProfile(response.data.data.accessToken)
        } catch (error) {
            console.log(error)
            if(error.response.data.errorCode === 'email-confirmation-pending'){
                setPendingEmailConfirmation(true)
            } else {
                dispatch({
                    type: ERROR,
                    error
                })
            }
            setProcessing(false)
        }
    }

    const [displayPendingCareHomes, setDisplayPendingCareHomes] = useState(false);
    const [pendingEmailConfirmation, setPendingEmailConfirmation] = useState(false);

    const getUserProfile = async  (token) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/profile?expand=careHomes.careHome,careHomes.role`, { headers })     
            console.log('---> ', response)       

            if(!response.data.data.careHomes || response.data.data.careHomes.length === 0){
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: 'Sorry, you cannot log in to this platform, you have no care homes registered.'
                    }}}
                })
                setProcessing(false)
                localStorage.removeItem("authToken")
                return
            }

            if(response.data.data.careHomes || response.data.data.careHomes.length > 0){
                const indexOfFirstApprovedCareHome = response.data.data.careHomes.findIndex(home => {return home.careHome.review.status === 'approved' &&  home.careHome.status === 'active'})
                if(indexOfFirstApprovedCareHome > - 1) {
                    localStorage.setItem("userDetails", JSON.stringify(response.data.data));
                    localStorage.setItem("activeCareHome", JSON.stringify(response.data.data.careHomes[indexOfFirstApprovedCareHome]));
                    navigate("/admin")
                } else {
                    setDisplayPendingCareHomes(true)
                    setProcessing(false)
                    return
                }
                
            } else {
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: 'Sorry, you cannot log in to this platform, you have no care homes registered.'
                    }}}
                })
                localStorage.removeItem("authToken")
                setProcessing(false)
                return
            }

        } catch (error) {
            console.error(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        }
    };

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const resendConfirmation = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json'
            }
            const requestPayload = { 
                email: email,
            }
            
            await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/email-confirmation/resend`, requestPayload, { headers }) 
            // setProcessing(false)
            setConfirmationModalOpen(true)

        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            // setProcessing(false)
        }
    }

    return (
        <>
            <div className="relative flex flex-row w-full bg-doiteasy-light-gray">            
                {getWindowDimensions().width < 800 && 
                <div className='w-full absolute bg-black bg-opacity-50 h-screen z-50 flex items-center justify-center'>
                    <div className='bg-primary rounded-lg p-10 w-10/12 resolution-warning'>
                        <p className='text-2xl text-accent mb-5'>Low resolution detected</p>
                        <p className='text-md'>For best performance and experience, kindly log in from a computer screen</p>
                    </div>
                </div>
                }
                <div className="w-1/2 min-h-screen flex items-center justify-center mx-auto">
                    {/* <div className='w-full'> */}
                        <div className="w-[80%]">
                            <DoiteasyLogo />

                            <div className='rounded-[32px] mt-[20px] bg-white px-[60px] py-[80px]'>
                                <h4 className="text-[24px] text-doiteasy-black -tracking-[0.05em] font-[550] font-space-grotesk text-center mx-auto">Sign in</h4>
                                <p className="text-center mt-[12px] text-gray-400">Please provide your email address and password to proceed to the administrator dashboard</p>

                                <div className='mt-4'>
                                    <TextField
                                        inputLabel="Email address" 
                                        fieldId="username" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={'Your registered email address'}
                                        hasError={validationErrors && validationErrors.email} 
                                        returnFieldValue={(value)=>{setEmail(value)}}
                                    />
                                </div>
                                
                                <div className='mt-4'>
                                    <PasswordField
                                        inputLabel="Password" 
                                        fieldId="password" 
                                        inputType="password" 
                                        inputPlaceholder={'Your password'}
                                        preloadValue={''}
                                        hasError={validationErrors && validationErrors.password} 
                                        returnFieldValue={(value)=>{setPassword(value)}}
                                    />
                                </div>

                                {!displayPendingCareHomes && !pendingEmailConfirmation && <>
                                    <p className="my-3 text-sm text-opacity-70 block text-right">Forgot your password? <Link to="/password-reset" className="text-secondary">Click here to reset it</Link></p>
                                    
                                    <div className='mt-8 w-[60%] mx-auto'>
                                        <FormButton buttonLabel="Login to your account" buttonAction={()=>{logIn()}} processing={processing} />
                                    </div>
                                </>}

                                {displayPendingCareHomes && <div className='mt-5 w-[80%] mx-auto text-center p-[20px] rounded-[10px] bg-gray-100 border-2 border-red-100 relative'>
                                    <button className='absolute right-[10px] top-[10px]' onClick={()=>{setDisplayPendingCareHomes(false)}}><CloseIcon className={'w-4 h-4'} /></button>
                                    <p className='font-space-grotesk text-xl text-red-400'>Care home in review</p>
                                    <p className='mt-[5px] text-sm font-[500] text-gray-600'>Sorry, we are still reviewing your care home. You will receive an email at your registered email address once it is approved.</p>
                                </div>}

                                {pendingEmailConfirmation && <div className='mt-5 w-[80%] mx-auto text-center p-[20px] rounded-[10px] bg-gray-100 border-2 border-red-100 relative'>
                                    <button className='absolute right-[10px] top-[10px]' onClick={()=>{setDisplayPendingCareHomes(false)}}><CloseIcon className={'w-4 h-4'} /></button>
                                    <p className='font-space-grotesk text-xl text-red-400'>Your email confirmation is pending</p>
                                    <p className='mt-[5px] text-sm font-[500] text-gray-600'>It appears you have not confirmed your email yet. Please use the button below to resend the confirmation code and proceed.</p>
                                    <div className='mt-8 w-[60%] mx-auto'>
                                        <FormButton buttonLabel="Resend confirmation code" buttonAction={()=>{resendConfirmation()}} processing={processing} />
                                    </div>
                                </div>}
                                    
                                <div className='w-full text-center mt-8'>
                                    <p className="my-3 text-sm text-opacity-70 block text-center text-gray-400">Don't have an account? <Link to="/signup" className="text-doiteasy-black relative">Sign up here <img src={UnderlineVector} alt='' className='absolute -bottom-[20px] right-0' /></Link></p>
                                </div>
                            </div>

                            {/* {error && error!=='' &&  
                                <ErrorMessage message={error} dismissHandler={()=>{setError(false)}} />
                            } */}
                        </div>
                    {/* </div> */}
                </div>
                <div className="w-1/2 h-screen flex items-center justify-center relative">
                    <div className='min-h-[90vh] h-inherit bg-doiteasy-blue rounded-[32px] w-[90%] mx-auto px-[70px] py-[120px] relative'>
                        <h1 className='text-white font-space-grotesk -tracking-[0.05em] text-center font-[550] text-[36px] leading-[40px]'>Around here, we doiteasy!</h1>

                        <p className='text-center text-blue-200 text-[18px] font-[550] mt-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>

                        <div className='flex items-center justify-between mt-6'>
                            <button className=''>
                                <ChevronIcon className={`text-white w-5 h-5`} />
                            </button>

                            <div className='flex items-center justify-center gap-x-[10px]'>
                                <button className='w-[10px] h-[10px] rounded-full bg-white'></button>
                                <button className='w-[10px] h-[10px] rounded-full bg-blue-400'></button>
                                <button className='w-[10px] h-[10px] rounded-full bg-blue-400'></button>
                                <button className='w-[10px] h-[10px] rounded-full bg-blue-400'></button>
                            </div>
                            <button className=''>
                                <ChevronIcon className={`text-white rotate-180 w-5 h-5`} />
                            </button>
                        </div>
                        <img src={DashMockup} className=' left-[5%] absolute bottom-0 w-[90%]' alt='dashboard' />
                    </div>
                    {/* <h1 className="text-4xl text-secondary font-bold mb-5 font-wix-mf-display">Benue State Judiciary</h1> */}
                </div>
            </div>

            <ModalDialog
                shown={confirmationModalOpen} 
                closeFunction={()=>{setConfirmationModalOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle=''
                maxWidthClass='max-w-lg'
                hideActions={true}
            >
                <EmailConfirmation 
                    email={email}
                    closeModalOnSuccess={()=>{
                        setConfirmationModalOpen(false)
                        // setActiveStep(1)
                        navigate('signup?continueFrom=1')
                    }}
                />
            </ModalDialog>
        </>
    )
}

export default LoginPage
