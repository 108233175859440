import { ACADEMIC_SESSIONS_ERROR, CREATE_ACADEMIC_SESSION, CREATING_ACADEMIC_SESSION, GETTING_ACADEMIC_SESSIONS, GET_ACADEMIC_SESSIONS, UPDATE_ACADEMIC_SESSION, UPDATING_ACADEMIC_SESSION } from "../types"

const initialState = {
    academicSessions: [],
    loadingAcademicSessions: true,
    academicSessionsError: null,
    creatingAcademicSession: false,
    createdAcademicSession: null,
    updatingAcademicSession: false,
    updatedAcademicSession: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_ACADEMIC_SESSIONS:
        return {
            ...state,
            loadingAcademicSessions :action.payload,
        }
        case GET_ACADEMIC_SESSIONS:
        return{
            ...state,
            loadingAcademicSessions:false,
            academicSessionsError:  null,
            academicSessions: action.payload,
        }
        case UPDATING_ACADEMIC_SESSION:
        return {
            ...state,
            updatingAcademicSessions: action.payload,
        }
        case UPDATE_ACADEMIC_SESSION:
        return{
            ...state,
            updatingAcademicSession:false,
            academicSessionsError: null,
            updatedAcademicSession: action.payload,
        }
        case CREATING_ACADEMIC_SESSION:
        return {
            ...state,
            creatingAcademicSessions: action.payload,
        }
        case CREATE_ACADEMIC_SESSION:
        return{
            ...state,
            creatingAcademicSession:false,
            academicSessionsError: null,
            createdAcademicSession: action.payload,
        }
        case ACADEMIC_SESSIONS_ERROR:
        return{
            ...state,
            loadingAcademicSessions:false,
            creatingAcademicSession:false,
            updatingAcademicSession:false,
            documentRequestsError: action.payload 
        }
        default: return state
    }

}