import { CREATE_EXAMINATION_CENTER, CREATING_EXAMINATION_CENTER, DELETE_EXAMINATION_CENTER, DELETING_EXAMINATION_CENTER, EXAMINATION_CENTERS_ERROR, GETTING_EXAMINATION_CENTERS, GET_EXAMINATION_CENTERS, UPDATE_EXAMINATION_CENTER, UPDATING_EXAMINATION_CENTER } from "../types"

const initialState = {
    examinationCenters: [],
    loadingExaminationCenters: true,
    examinationCentersError: null,
    creatingExaminationCenter: false,
    createdExaminationCenter: null,
    updatingExaminationCenter: false,
    updatedExaminationCenter: null,
    deletingExaminationCenter: false,
    deletedExaminationCenter: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case CREATING_EXAMINATION_CENTER:
        return {
            ...state,
            creatingExaminationCenter :action.payload,
        }
        case CREATE_EXAMINATION_CENTER:
        return{
            ...state,
            creatingExaminationCenter:false,
            examinationCentersError:  null,
            createdExaminationCenter: action.payload,
        }
        case UPDATING_EXAMINATION_CENTER:
        return {
            ...state,
            updatingExaminationCenter: action.payload,
        }
        case UPDATE_EXAMINATION_CENTER:
        return{
            ...state,
            updatingExaminationCenter:false,
            examinationCentersError:  null,
            updatedExaminationCenter: action.payload,
        }
        case DELETING_EXAMINATION_CENTER:
        return {
            ...state,
            deletingExaminationCenter: action.payload,
        }
        case DELETE_EXAMINATION_CENTER:
        return{
            ...state,
            deletingExaminationCenter:false,
            examinationCentersError:  null,
            deletedExaminationCenter: action.payload,
        }
        case GETTING_EXAMINATION_CENTERS:
        return {
            ...state,
            loadingExaminationCenters :action.payload,
        }
        case GET_EXAMINATION_CENTERS:
        return{
            ...state,
            loadingExaminationCenters:false,
            examinationCentersError:  null,
            examinationCenters: action.payload,
        }
        case EXAMINATION_CENTERS_ERROR:
        return{
            ...state,
            loadingExaminationCenters:false,
            creatingExaminationCenter:false,
            updatingExaminationCenter:false,
            examinationCenters: action.payload 
        }
        default: return state
    }

}