import React from 'react'
import { unSlugify } from '../../utils'

const Status = ({status}) => {
  return (
    <div className='flex items-center gap-x-[5px] capitalize mb-[20px]'>
        <div className={`rounded-full w-[5px] h-[5px] 
            ${status === 'in_progress' && 'bg-yellow-600'}
            ${status === 'completed' && 'bg-green-600'}
        `} />
        <p className='text-doiteasy-black text-[14px]'>{unSlugify(status)}</p>
    </div>
  )
}

export default Status