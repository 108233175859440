import { CREATE_DOCUMENT_REQUEST, CREATING_DOCUMENT_REQUEST, DOCUMENT_REQUESTS_ERROR, GETTING_DOCUMENT_REQUESTS, GET_DOCUMENT_REQUESTS } from "../types"

const initialState = {
    documentRequests: [],
    loadingDocumentRequests: true,
    documentRequestsError: null,
    creatingDocumentRequest: false,
    createdDocumentRequest: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_DOCUMENT_REQUESTS:
        return {
            ...state,
            loadingDocumentRequests :action.payload,
        }
        case GET_DOCUMENT_REQUESTS:
        return{
            ...state,
            loadingDocumentRequests:false,
            documentRequestsError:  null,
            documentRequests: action.payload,
        }
        case CREATING_DOCUMENT_REQUEST:
        return {
            ...state,
            creatingDocumentRequest: action.payload,
        }
        case CREATE_DOCUMENT_REQUEST:
        return{
            ...state,
            creatingDocumentRequest:false,
            documentRequestsError: null,
            createdDocumentRequest: action.payload,
        }
        case DOCUMENT_REQUESTS_ERROR:
        return{
            ...state,
            loadingDocumentRequests:false,
            creatingDocumentRequest:false,
            documentRequestsError: action.payload 
        }
        default: return state
    }

}