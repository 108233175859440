import { APPLICATIONS_ERROR, CREATE_APPLICATION, CREATING_APPLICATION, GETTING_APPLICATIONS, GET_APPLICATIONS, UPDATED_APPLICATION, UPDATING_APPLICATION } from "../types"


const initialState = {
    applications: [],
    loadingApplications: true,
    applicationsError: null,
    creatingApplication: false,
    createdApplication: null,
    updatingApplication: false,
    updatedApplication: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_APPLICATIONS:
        return {
            ...state,
            loadingApplications :action.payload,
        }
        case GET_APPLICATIONS:
        return{
            ...state,
            loadingApplications:false,
            applicationsError:  null,
            applications: action.payload,
        }
        case CREATING_APPLICATION:
        return {
            ...state,
            creatingApplication: action.payload,
        }
        case CREATE_APPLICATION:
        return{
            ...state,
            creatingApplication:false,
            applicationsError: null,
            createdApplication: action.payload,
        }
        case UPDATING_APPLICATION:
        return {
            ...state,
            updatingApplication: action.payload,
        }
        case UPDATED_APPLICATION:
        return{
            ...state,
            updatingApplication:false,
            applicationsError: null,
            updatedApplication: action.payload,
        }
        case APPLICATIONS_ERROR:
        return{
            ...state,
            loadingApplications:false,
            updatingApplication: false,
            creatingApplication:false,
            applicationsError: action.payload 
        }
        default: return state
    }

}