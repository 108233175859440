import React, { Fragment, useState } from 'react'
import AdminHeader from '../partials/AdminHeader'
import CollapsedSidebar from '../partials/CollapsedSidebar'
import Sidebar from '../partials/Sidebar'
import { activeCareHome, defaultSidebarState } from '../../utils'
import ChevronIcon from '../elements/icons/ChevronIcon'
import OverviewGrayScale from '../../assets/images/icons/overview-icon-grayscale.svg'
import OverviewWhite from '../../assets/images/icons/overview-icon.svg'
import UserIconGrayscale from '../../assets/images/icons/user-icon.svg'
import UserIconWhite from '../../assets/images/icons/user-icon-white.svg'
import RoadmapGrayscale from '../../assets/images/icons/roadmap-icon.svg'
import RoadmapWhite from '../../assets/images/icons/roadmap-icon-white.svg'
import VisitorsGrayscale from '../../assets/images/icons/visitors-icon.svg'
import VisitorsWhite from '../../assets/images/icons/visitors-icon-white.svg'
// import HeartPlusGrayscale from '../../assets/images/icons/heart-plus-icon.svg'
// import HeartPlusWhite from '../../assets/images/icons/heart-plus-icon-white.svg'
import CalendarGrayscale from '../../assets/images/icons/calendar-icon.svg'
import CalendarWhite from '../../assets/images/icons/calendar-icon-white.svg'
import InventoryInGrayscale from '../../assets/images/icons/inventory-in-icon.svg'
import InventoryInWhite from '../../assets/images/icons/inventory-in-icon-white.svg'
import WatchGrayscale from '../../assets/images/icons/watch-icon.svg'
import WatchWhite from '../../assets/images/icons/watch-icon-white.svg'
import BagGrayscale from '../../assets/images/icons/bag-icon.svg'
import BagWhite from '../../assets/images/icons/bag-icon-white.svg'
import SupplierGrayscale from '../../assets/images/icons/supplier-icon.svg'
import SupplierWhite from '../../assets/images/icons/supplier-icon-white.svg'
import HandHeartGrayscale from '../../assets/images/icons/hand-heart-icon.svg'
import HandHeartWhite from '../../assets/images/icons/hand-heart-icon-white.svg'
import DefaultCareHomeLogo from '../../assets/images/icons/care-home-icon.svg'
import DefaultCareHomeLogoWhite from '../../assets/images/icons/care-home-icon-white.svg'
import PieChart from '../../assets/images/icons/pie-chart-icon.svg'
import PieChartWhite from '../../assets/images/icons/pie-chart-icon-white.svg'

const Layout = ({children, pageTitle}) => {
    const [sidebarState, setSidebarState] = useState(defaultSidebarState() || 'closed');
    const toggleSidebar = () => {
        if(sidebarState === 'closed') {
            localStorage.setItem("defaultSidebarState", "open");
            setSidebarState("open")
        } else {
            localStorage.setItem("defaultSidebarState", "closed");
            setSidebarState("closed")
        }
    }

    const careHome = activeCareHome()

    const sidebarLinks = [
        {
            title: '',
            icon: '',
            activeCheck: 'admin/dashboard',
            links: [
                {
                    title: 'Overview',
                    icon: OverviewGrayScale,
                    activeIcon: OverviewWhite,
                    route: '/admin/dashboard/overview',
                    activeCheck: '/admin/dashboard/overview'
                },
                {
                    title: 'Getting Started',
                    icon: RoadmapGrayscale,
                    activeIcon: RoadmapWhite,
                    route: '/admin/dashboard/getting-started',
                    activeCheck: '/admin/dashboard/getting-started'
                },
            ]
        },
        {
            title: 'Carehome',
            icon: DefaultCareHomeLogo,
            activeIcon: DefaultCareHomeLogoWhite,
            activeCheck: '/admin/care-home',
            links: [
                {
                    title: 'Residents',
                    icon: UserIconGrayscale,
                    activeIcon: UserIconWhite,
                    route: '/admin/care-home/residents',
                    activeCheck: '/admin/care-home/residents'
                },
                {
                    title: 'Caregivers',
                    icon: HandHeartGrayscale,
                    activeIcon: HandHeartWhite,
                    route: '/admin/care-home/caregivers',
                    activeCheck: '/admin/care-home/caregivers'
                },
                {
                    title: 'Schedules',
                    icon: CalendarGrayscale,
                    activeIcon: CalendarWhite,
                    route: '/admin/care-home/schedules',
                    activeCheck: '/admin/care-home/schedules'
                },
                {
                    title: 'Departments',
                    icon: VisitorsGrayscale,
                    activeIcon: VisitorsWhite,
                    route: '/admin/care-home/visitor-logs',
                    activeCheck: '/admin/care-home/visitor-logs'
                },
                {
                    title: 'Visitor Logs',
                    icon: VisitorsGrayscale,
                    activeIcon: VisitorsWhite,
                    route: '/admin/care-home/visitor-logs',
                    activeCheck: '/admin/care-home/visitor-logs'
                },
                {
                    title: 'Caregiver attendance',
                    icon: VisitorsGrayscale,
                    activeIcon: VisitorsWhite,
                    route: '/admin/care-home/visitor-logs',
                    activeCheck: '/admin/care-home/visitor-logs'
                },
            ]
        },
        {
            title: 'Inventory',
            icon: InventoryInGrayscale,
            activeIcon: InventoryInWhite,
            activeCheck: '/admin/inventory',
            links: [
                {
                    title: 'Items',
                    icon: InventoryInGrayscale,
                    activeIcon: InventoryInWhite,
                    route: '/admin/inventory/items',
                    activeCheck: '/admin/inventory/items'
                },
                {
                    title: 'Hotlist',
                    icon: WatchGrayscale,
                    activeIcon: WatchWhite,
                    route: '/admin/inventory/hotlist',
                    activeCheck: '/admin/inventory/hotlist'
                },
                {
                    title: 'Orders',
                    icon: BagGrayscale,
                    activeIcon: BagWhite,
                    route: '/admin/inventory/orders',
                    activeCheck: '/admin/inventory/orders'
                },
                {
                    title: 'Suppliers',
                    icon: SupplierGrayscale,
                    activeIcon: SupplierWhite,
                    route: '/admin/inventory/suppliers',
                    activeCheck: '/admin/inventory/suppliers'
                },
            ]
        },
        {
            title: 'Reports',
            icon: PieChart,
            activeIcon: PieChartWhite,
            activeCheck: '/admin/reports',
            links: [
                {
                    title: 'Facility Overview',
                    icon: DefaultCareHomeLogo,
                    activeIcon: DefaultCareHomeLogoWhite,
                    route: '/admin/reports/facility-overview',
                    activeCheck: '/admin/reports/facility-overview'
                },
                {
                    title: 'Resident Overview',
                    icon: UserIconGrayscale,
                    activeIcon: UserIconWhite,
                    route: '/admin/reports/resident-overview',
                    activeCheck: '/admin/reports/resident-overview'
                },
                {
                    title: 'Financial Reports',
                    icon: SupplierGrayscale,
                    activeIcon: SupplierWhite,
                    route: '/admin/reports/financial-reports',
                    activeCheck: '/admin/reports/financial-reports'
                },
                {
                    title: 'Caregivers overview',
                    icon: HandHeartGrayscale,
                    activeIcon: HandHeartWhite,
                    route: '/admin/reports/caregiver-overview',
                    activeCheck: '/admin/reports/caregiver-overview'
                },
            ]
        },
    ]

    return (
        <Fragment>
            <div className="flex flex-row">
                <div className={`min-h-screen z-40 relative ${sidebarState === "open" ? 'w-1/6' : 'w-[50px]'}`}>
                    <div className={`w-[35px] fixed z-50 left- top-[30px] ${sidebarState === "open" ? 'left-[240px]' : 'left-[60px]'}`}>
                        <button className="text-primary border border-[#2684FC1F] rounded-[8px] shadow-xl flex items-center justify-center w-[32px] h-[32px] text-opacity-70 hover:text-opacity-90 transition duration-200" onClick={()=>{toggleSidebar()}}>
                            <ChevronIcon className={`w-[18px] h-[18px] transition duration-200 text-[#1E2875] ${sidebarState === 'closed' && 'rotate-180'}`} />
                        </button>
                    </div>
                    {sidebarState === "open" ? <div className="h-screen w-1/6 fixed overflow-y-scroll scrollbar-hidden">
                        <Sidebar sections={sidebarLinks} toggleFunction={toggleSidebar} careHome={careHome} />
                    </div>
                    :
                    <div className="h-screen bg-grey w-[80px] fixed">
                        <CollapsedSidebar sections={sidebarLinks} toggleFunction={toggleSidebar} careHome={careHome}   />
                    </div>}

                    <div className={`z-0 ${sidebarState === 'open' ? "w-1/6" : "w-[80px]"}`} />
                </div>
                <div className={`flex flex-col clear-left min-h-screen z-10 ${sidebarState === 'open' ? 'w-5/6' : 'w-full'}`}>
                    <div className={`fixed bg-white z-20 ${sidebarState === 'open' ? "w-5/6" : "w-full pl-[30px] pr-[70px]"}`}>
                        <AdminHeader 
                            pageTitle={pageTitle} 
                            sidebarActive={sidebarState === 'open'}  
                            sidebarToggleFunction={toggleSidebar} 
                        />
                    </div>
                    
                    <div className={`mt-20 bg-[#f9f9f9] min-h-screen py-10 ${sidebarState === 'open' ? 'px-4 2xl:px-10' : 'lg:px-8 2xl:px-32'}`}>
                        <main>{children}</main>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Layout
