import { GETTING_STUDENTS, GET_STUDENTS, STUDENTS_ERROR, UPDATED_STUDENT, UPDATING_STUDENT } from "../types"

const initialState = {
    students: [],
    loadingStudents: true,
    studentsError: null,
    // creatingApplication: false,
    // createdApplication: null,
    updatingStudent: false,
    updatedStudent: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_STUDENTS:
        return {
            ...state,
            loadingStudents :action.payload,
        }
        case GET_STUDENTS:
        return{
            ...state,
            loadingStudents:false,
            studentsError:  null,
            students: action.payload,
        }
        case UPDATING_STUDENT:
        return {
            ...state,
            updatingStudent: action.payload,
        }
        case UPDATED_STUDENT:
        return{
            ...state,
            updatingStudent:false,
            studentsError: null,
            updatedStudent: action.payload,
        }
        case STUDENTS_ERROR:
        return{
            ...state,
            loadingStudents:false,
            updatingStudent: false,
            // creatingApplication:false,
            studentsError: action.payload 
        }
        default: return state
    }

}