import { GETTING_PARENTS, GET_PARENTS, PARENTS_ERROR } from "../types"

const initialState = {
    parents: [],
    loadingParents: true,
    parentsError: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_PARENTS:
        return {
            ...state,
            loadingParents :action.payload,
        }
        case GET_PARENTS:
        return{
            ...state,
            loadingParents:false,
            parentsError:  null,
            parents: action.payload,
        }
        case PARENTS_ERROR:
        return{
            ...state,
            loadingParents:false,
            parentsError: action.payload 
        }
        default: return state
    }

}