import React from 'react'
import Layout from '../../components/layouts/Layout'

const AdminErrorPage = () => {
    return (
        <Layout pageTitle="Error">
            <div className="h-screen">
                <h1 className="font-space-grotesk font-medium text-3xl mt-32 mb-6">404: Page not found</h1>
                <p className="text-sm">Sorry, it seems you have stumbled on a page that doesn't, or no longer exist (sorry about that)... <br />Please use the links in the right sidebar to navigate</p>
            </div>
        </Layout>
    )
}

export default AdminErrorPage
