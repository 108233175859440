import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import DefaultCareHomeLogo from '../../assets/images/icons/care-home-icon.svg'
import DoiteasyLogoSmall from '../elements/DoiteasyLogoSmall';

const Sidebar = ({sections, careHome}) => {
    const location = useLocation();
    const currentRoute = location.pathname;

    return (
        <div className="w-full min-h-screen bg-white px-4 relative">
            <div className='mt-[20px]'>
                <DoiteasyLogoSmall />
            </div>
            
            <div className='rounded-[8px] mt-[10px] border-gray-300 flex items-center gap-x-[6px] mb-[24px]'>
                <img src={DefaultCareHomeLogo} alt='' className='w-[30px]' />
                <div className='w-full'>
                    <h3 className='font-space-grotesk text-doiteasy-black'>{careHome.careHome.name}</h3>
                    <p className='text-xs text-[#8D9696]'>{careHome.careHome.contact.email}</p>
                </div>
            </div>
            {sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className='mb-[8px]'>
                    {section.title && section.title !== '' && <h4 className='text-doiteasy-black font-[550] font-space-grotesk ml-[15px]'>{section.title}</h4>}
                    
                    {section.links.map((link, linkIndex)=>(
                        <NavLink 
                            key={linkIndex} 
                            to={link.route} 
                            className={`${currentRoute.includes(link.activeCheck) ? 'text-white bg-doiteasy-blue' : 'text-doiteasy-black'} text-sm flex px-[16px] py-[10px] rounded-[8px] w-[90%] flex-row gap-x-1 items-center font-[550]`}
                        >
                            <div className="w-[20px] flex items-center justify-center">
                                <img src={currentRoute.includes(link.activeCheck) ? link.activeIcon : link.icon} alt='' />
                            </div>
                            {link.title}
                        </NavLink>
                    ))}
                </div>
            ))}

        </div>
        
    )
}

export default Sidebar
