// user profile
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';

// Fees
export const GET_FEES = 'GET_FEES';
export const GETTING_FEES= 'GETTING_FEES';
export const CREATE_FEE= 'CREATE_FEE';
export const CREATING_FEE = 'CREATING_FEE';
export const UPDATE_FEE= 'UPDATE_FEE';
export const UPDATING_FEE = 'UPDATING_FEE';
export const FEES_ERROR = 'FEES_ERROR';

// Classes
export const GET_CLASSES = 'GET_CLASSES';
export const GETTING_CLASSES = 'GETTING_CLASSES';
export const UPDATE_CLASS = 'UPDATE_CLASS';
export const UPDATING_CLASS = 'UPDATING_CLASS';
export const CLASSES_ERROR = 'CLASSES_ERROR';

// Examinations
export const GET_EXAMINATIONS = 'GET_EXAMINATIONS';
export const GETTING_EXAMINATIONS = 'GETTING_EXAMINATIONS';
export const CREATE_EXAMINATION = 'CREATE_EXAMINATION';
export const CREATING_EXAMINATION = 'CREATING_EXAMINATION';
export const UPDATE_EXAMINATION = 'UPDATE_EXAMINATION';
export const UPDATING_EXAMINATION = 'UPDATING_EXAMINATION';
export const EXAMINATIONS_ERROR = 'EXAMINATIONS_ERROR';

// Examination centers
export const GET_EXAMINATION_CENTERS = 'GET_EXAMINATION_CENTERS';
export const GETTING_EXAMINATION_CENTERS= 'GETTING_EXAMINATION_CENTERS';
export const CREATE_EXAMINATION_CENTER= 'CREATE_EXAMINATION_CENTER';
export const CREATING_EXAMINATION_CENTER = 'CREATING_EXAMINATION_CENTER';
export const UPDATE_EXAMINATION_CENTER = 'UPDATE_EXAMINATION_CENTER';
export const UPDATING_EXAMINATION_CENTER= 'UPDATING_EXAMINATION_CENTER';
export const DELETE_EXAMINATION_CENTER = 'DELETE_EXAMINATION_CENTER';
export const DELETING_EXAMINATION_CENTER= 'DELETING_EXAMINATION_CENTER';
export const EXAMINATION_CENTERS_ERROR = 'EXAMINATION_CENTERS_ERROR';

// Academic sessions
export const GET_ACADEMIC_SESSIONS = 'GET_ACADEMIC_SESSIONS';
export const GETTING_ACADEMIC_SESSIONS= 'GETTING_ACADEMIC_SESSIONS';
export const CREATE_ACADEMIC_SESSION = 'CREATE_ACADEMIC_SESSION';
export const CREATING_ACADEMIC_SESSION = 'CREATING_ACADEMIC_SESSION';
export const UPDATE_ACADEMIC_SESSION= 'UPDATE_ACADEMIC_SESSION';
export const UPDATING_ACADEMIC_SESSION= 'UPDATING_ACADEMIC_SESSION';
export const ACADEMIC_SESSIONS_ERROR = 'ACADEMIC_SESSIONS_ERROR';

// Departments
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GETTING_DEPARTMENTS = 'GETTING_DEPARTMENTS';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const CREATING_DEPARTMENT = 'GETTING_DEPARTMENTS';
export const UPDATING_DEPARTMENT = 'UPDATING_DEPARTMENT';
export const UPDATED_DEPARTMENT = 'UPDATED_DEPARTMENT';
export const DELETING_DEPARTMENT = 'DELETING_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const DEPARTMENTS_ERROR = 'DEPARTMENTS_ERROR';

// Documents
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GETTING_DOCUMENTS = 'GETTING_DEPARTMENTS';
export const CREATE_DOCUMENT = 'CREATE_DEPARTMENT';
export const UPDATING_DOCUMENT = 'UPDATING_DOCUMENTS';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const CREATING_DOCUMENT = 'GETTING_DEPARTMENTS';
export const DOCUMENTS_ERROR = 'DEPARTMENTS_ERROR';

// Groups
export const GET_GROUPS = 'GET_GROUPS';
export const GETTING_GROUPS = 'GETTING_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATING_GROUP = 'CREATING_GROUP';
export const GROUPS_ERROR = 'GROUPS_ERROR';

// Document requests
export const GET_DOCUMENT_REQUESTS = 'GET_DOCUMENT_REQUESTS';
export const GETTING_DOCUMENT_REQUESTS = 'GETTING_DOCUMENT_REQUESTS';
export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATING_DOCUMENT_REQUEST = 'CREATING_DOCUMENT_REQUEST';
export const DOCUMENT_REQUESTS_ERROR = 'DOCUMENT_REQUESTS_ERROR';

// Admissions
export const CREATE_ADMISSION = 'CREATE_ADMISSION';
export const CREATING_ADMISSION = 'CREATING_ADMISSION';
export const UPDATE_ADMISSION= 'UPDATE_ADMISSION';
export const UPDATING_ADMISSION = 'UPDATING_ADMISSION';
export const GET_ADMISSIONS = 'GET_ADMISSIONS';
export const GETTING_ADMISSIONS = 'GETTING_ADMISSIONS';
export const ADMISSIONS_ERROR = 'ADMISSIONS_ERROR';

// Payments
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GETTING_PAYMENTS = 'GETTING_PAYMENTS';
export const PAYMENTS_ERROR = 'PAYMENTS_ERROR';

// Payments
export const GET_PARENTS = 'GET_PARENTS';
export const GETTING_PARENTS = 'GETTING_PARENTS';
export const PARENTS_ERROR = 'PARENTS_ERROR';

// Applications
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GETTING_APPLICATIONS = 'GETTING_APPLICATIONS';
export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATING_APPLICATION = 'CREATING_APPLICATION';
export const UPDATING_APPLICATION = 'UPDATING_APPLICATION';
export const UPDATED_APPLICATION = 'UPDATED_APPLICATION';
export const APPLICATIONS_ERROR = 'APPLICATIONS_ERROR';

// Application responses
export const GET_APPLICATION_RESPONSES = 'GET_APPLICATION_RESPONSES';
export const GETTING_APPLICATION_RESPONSES = 'GETTING_APPLICATION_RESPONSES';
export const CREATE_APPLICATION_RESPONSE = 'CREATE_APPLICATION_RESPONSE';
export const CREATING_APPLICATION_RESPONSE = 'CREATING_APPLICATION_RESPONSE';
export const APPLICATION_RESPONSES_ERROR = 'APPLICATION_RESPONSES_ERROR';

// Students
export const GET_STUDENTS = 'GET_STUDENTS';
export const GETTING_STUDENTS = 'GETTING_STUDENTS';
export const UPDATING_STUDENT = 'UPDATING_STUDENT';
export const UPDATED_STUDENT = 'UPDATED_STUDENT';
export const STUDENTS_ERROR = 'STUDENTS_ERROR';

// Staff
export const GET_STAFF = 'GET_STAFF';
export const GETTING_STAFF = 'GETTING_STAFF';
export const ONBOARDING_STAFF = 'ONBOARDING_STAFF';
export const ONBOARD_STAFF = 'ONBOARD_STAFF';
export const UPDATING_STAFF = 'UPDATING_STAFF';
export const UPDATED_STAFF = 'UPDATED_STAFF';
export const STAFF_ERROR = 'STAFF_ERROR';

// Designations
export const GET_DESIGNATIONS = 'GET_DESIGNATIONS';
export const GETTING_DESIGNATIONS = 'GETTING_DESIGNATIONS';
export const CREATE_DESIGNATION = 'CREATE_DESIGNATION';
export const CREATING_DESIGNATION = 'CREATING_DESIGNATION';
export const UPDATE_DESIGNATION = 'UPDATE_DESIGNATION';
export const UPDATING_DESIGNATION = 'UPDATING_DESIGNATION';
export const DESIGNATIONS_ERROR = 'DESIGNATIONS_ERROR';

// Roles & Permissions
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GETTING_PERMISSIONS = 'GETTING_PERMISSIONS';
export const GET_ROLES = 'GET_ROLES';
export const GETTING_ROLES = 'GETTING_ROLES';
export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATING_ROLE = 'CREATING_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATING_ROLE = 'UPDATING_ROLE';
export const ROLES_PERMISSIONS_ERROR = 'ROLES_PERMISSIONS_ERROR';

// Audit logs
export const GET_AUDIT_LOGS = 'GET_AUDIT_LOGS';
export const GETTING_AUDIT_LOGS = 'GETTING_AUDIT_LOGS';
export const AUDIT_LOGS_ERROR = 'AUDIT_LOGS_ERROR';

// Errors
export const ERROR = 'ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// Success
export const SET_SUCCESS = 'SET_SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'