import { CREATE_FEE, CREATING_FEE, FEES_ERROR, GETTING_FEES, GET_FEES, UPDATE_FEE, UPDATING_FEE } from "../types"


const initialState = {
    fees: [],
    loadingFees: true,
    feesError: null,
    creatingFee: false,
    createdFee: null,
    updatingFee: false,
    updatedFee: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case CREATING_FEE:
        return {
            ...state,
            creatingFee :action.payload,
        }
        case CREATE_FEE:
        return{
            ...state,
            creatingFee:false,
            feesError:  null,
            createdFee: action.payload,
        }
        case UPDATING_FEE:
        return {
            ...state,
            updatingFee :action.payload,
        }
        case UPDATE_FEE:
        return{
            ...state,
            updatingFee:false,
            feesError:  null,
            updatedFee: action.payload,
        }
        case GETTING_FEES:
        return {
            ...state,
            loadingFees :action.payload,
        }
        case GET_FEES:
        return{
            ...state,
            loadingFees:false,
            feesError:  null,
            fees: action.payload,
        }
        case FEES_ERROR:
        return{
            ...state,
            loadingAdmissions:false,
            admissionsError: action.payload 
        }
        default: return state
    }

}