import { CREATE_EXAMINATION, CREATING_EXAMINATION, EXAMINATIONS_ERROR, GETTING_EXAMINATIONS, GET_EXAMINATIONS, UPDATE_EXAMINATION, UPDATING_EXAMINATION } from "../types"



const initialState = {
    examinations: [],
    loadingExaminations: true,
    examinationsError: null,
    creatingExamination: false,
    createdExamination: null,
    updatingExamination: false,
    updatedExamination: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case CREATING_EXAMINATION:
        return {
            ...state,
            creatingExamination :action.payload,
        }
        case CREATE_EXAMINATION:
        return{
            ...state,
            creatingExamination:false,
            examinationsError:  null,
            createdExamination: action.payload,
        }
        case UPDATING_EXAMINATION:
        return {
            ...state,
            updatingExamination: action.payload,
        }
        case UPDATE_EXAMINATION:
        return{
            ...state,
            updatingExamination:false,
            examinationsError:  null,
            updatedExamination: action.payload,
        }
        case GETTING_EXAMINATIONS:
        return {
            ...state,
            loadingExaminations :action.payload,
        }
        case GET_EXAMINATIONS:
        return{
            ...state,
            loadingExaminations:false,
            examinationsError:  null,
            examinations: action.payload,
        }
        case EXAMINATIONS_ERROR:
        return{
            ...state,
            loadingExaminations:false,
            creatingExamination:false,
            updatingExamination:false,
            examinationsError: action.payload 
        }
        default: return state
    }

}