import { APPLICATION_RESPONSES_ERROR, CREATE_APPLICATION_RESPONSE, CREATING_APPLICATION_RESPONSE, GETTING_APPLICATION_RESPONSES, GET_APPLICATION_RESPONSES } from "../types"

const initialState = {
    responses: [],
    loadingResponses: true,
    responsesError: null,
    creatingResponse: false,
    createdResponse: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_APPLICATION_RESPONSES:
        return {
            ...state,
            loadingResponses :action.payload,
        }
        case GET_APPLICATION_RESPONSES:
        return{
            ...state,
            loadingResponses:false,
            responsesError:  null,
            responses: action.payload,
        }
        case CREATING_APPLICATION_RESPONSE:
        return {
            ...state,
            creatingResponse: action.payload,
        }
        case CREATE_APPLICATION_RESPONSE:
        return{
            ...state,
            creatingResponse:false,
            responsesError: null,
            createdResponse: action.payload,
        }
        // case UPDATING_APPLICATION:
        // return {
        //     ...state,
        //     updatingApplication: action.payload,
        // }
        // case UPDATED_APPLICATION:
        // return{
        //     ...state,
        //     updatingApplication:false,
        //     applicationsError: null,
        //     updatedApplication: action.payload,
        // }
        case APPLICATION_RESPONSES_ERROR:
        return{
            ...state,
            loadingResponses:false,
            creatingResponse:false,
            responsesError: action.payload 
        }
        default: return state
    }

}