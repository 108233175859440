import { CREATE_DEPARTMENT, CREATING_DEPARTMENT, DELETE_DEPARTMENT, DELETING_DEPARTMENT, DEPARTMENTS_ERROR, GETTING_DEPARTMENTS, GET_DEPARTMENTS, UPDATED_DEPARTMENT, UPDATING_DEPARTMENT } from "../types"


const initialState = {
    departments: [],
    loadingDepartments: false,
    departmentsError: null,
    creatingDepartments: false,
    createdDepartment: null,
    updatingDepartment: false,
    updatedDepartment: null,
    deletingDepartment: false,
    deletedDepartment: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_DEPARTMENTS:
        return {
            ...state,
            loadingDepartments:action.payload,
            // fetchingMembers:false
        }
        case GET_DEPARTMENTS:
        return{
            ...state,
            loadingDepartments:false,
            departmentsError: false,
            departments: action.payload,
        }
        case CREATING_DEPARTMENT:
        return {
            ...state,
            creatingDepartments:action.payload,
            // fetchingMembers:false
        }
        case CREATE_DEPARTMENT:
        return{
            ...state,
            creatingDepartment:false,
            departmentsError: false,
            createdDepartment: action.payload,
        }
        case UPDATING_DEPARTMENT:
        return {
            ...state,
            updatingDepartment:action.payload,
        }
        case UPDATED_DEPARTMENT:
        return{
            ...state,
            updatingDepartment:false,
            departmentsError: false,
            updatedDepartment: action.payload,
        }
        case DELETING_DEPARTMENT:
        return {
            ...state,
            deletingDepartment:action.payload,
        }
        case DELETE_DEPARTMENT:
        return{
            ...state,
            deletingDepartment:false,
            departmentsError: false,
            deletedDepartment: action.payload,
        }
        case DEPARTMENTS_ERROR:
        return{
            ...state,
            loadingDepartments:false,
            // departments: null,
            departmentsError: action.payload 
        }
        default: return state
    }

}