import { GETTING_PAYMENTS, GET_PAYMENTS, PAYMENTS_ERROR } from "../types"

const initialState = {
    payments: [],
    loadingPayments: true,
    paymentsError: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_PAYMENTS:
        return {
            ...state,
            loadingPayments :action.payload,
        }
        case GET_PAYMENTS:
        return{
            ...state,
            loadingPayments:false,
            paymentsError:  null,
            payments: action.payload,
        }
        case PAYMENTS_ERROR:
        return{
            ...state,
            loadingPayments:false,
            paymentsError: action.payload 
        }
        default: return state
    }

}