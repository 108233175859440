import { GETTING_STAFF, GET_STAFF, STAFF_ERROR, UPDATED_STAFF, UPDATING_STAFF } from "../types"


const initialState = {
    staffMembers: [],
    loadingStaff: true,
    staffError: null,
    onboardingStaff: false,
    onboardedStaff: null,
    updatingStaff: false,
    updatedStaff: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_STAFF:
        return {
            ...state,
            loadingStaff :action.payload,
        }
        case GET_STAFF:
        return{
            ...state,
            loadingStaff:false,
            staffError:  null,
            staffMembers: action.payload,
        }
        case UPDATING_STAFF:
        return {
            ...state,
            updatingStaff: action.payload,
        }
        case UPDATED_STAFF:
        return{
            ...state,
            loadingStaff: false,
            updatingStaff:false,
            staffError: null,
            updatedStaff: action.payload,
        }
        case STAFF_ERROR:
        return{
            ...state,
            loadingStaff: false,
            updatingStaff:false,
            staffError: action.payload 
        }
        default: return state
    }

}