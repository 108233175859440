import { combineReducers } from "redux";
import { errorReducer } from "./errorReducer";
import successReducer from "./successReducer";
import applicationsReducer from "./applicationsReducer";
import admissionsReducer from "./admissionsReducer";
import paymentsReducer from "./paymentsReducer";
import documentRequestReducer from "./documentRequestReducer";
import feesReducer from "./feesReducer";
import examinationsReducer from "./examinationsReducer";
import academicSessionsReducer from "./academicSessionsReducer";
import parentsReducer from "./parentsReducer";
import studentsReducer from "./studentsReducer";
import departmentReducer from "./departmentReducer";
import documentReducer from "./documentReducer";
import classesDivisionsReducer from "./classesDivisionsReducer";
import staffReducer from "./staffReducer";
import designationReducer from "./designationReducer";
import rolesPermissionsReducer from "./rolesPermissionsReducer";
import auditLogsReducer from "./auditLogsReducer";
import examinationCentersReducer from "./examinationCentersReducer";
import applicationResponsesReducer from "./applicationResponsesReducer";

const rootReducer = combineReducers({
    success: successReducer,
    errors: errorReducer,
    applications: applicationsReducer,
    admissions: admissionsReducer,
    payments: paymentsReducer,
    fees: feesReducer,
    documentRequests: documentRequestReducer,
    examinations: examinationsReducer,
    academicSessions: academicSessionsReducer,
    parents: parentsReducer,
    students: studentsReducer,
    departments: departmentReducer,
    documents: documentReducer,
    classes: classesDivisionsReducer,
    staff: staffReducer,
    designations: designationReducer,
    roles: rolesPermissionsReducer,
    auditLogs: auditLogsReducer,
    examinationCenters: examinationCentersReducer,
    applicationResponses: applicationResponsesReducer
});

export default rootReducer;