import React from 'react'
import PhoneCallIcon from '../../assets/images/icons/phone-call.svg'
import MessageIcon from '../../assets/images/icons/message-bubble.svg'
import Status from '../elements/Status'

const VisitorCard = ({visitor}) => {
  return (
    <div className='w-full'>
        <div className='w-full flex items-start justify-between pb-[10px] border-b border-gray-300 mb-[5px]'>
          <div>
            <p className='text-doiteasy-black text-[14px] font-[550] leading-[22px]'>{visitor.name}</p>
            <div className='flex items-center gap-x-[5px]'>
              <div className='w-[32px]'>
                <div className='w-[30px] h-[30px] rounded-full border-2 border-doiteasy-light-gray' style={{
                    backgroundImage: `url(${visitor.resident?.picture})`, 
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center'
                }} />
              </div>
              <p className='text-doiteasy-black text-opacity-60 text-[14px] leading-[22px]'>{visitor.resident.name}</p>
            </div>
          </div>
          <div className='flex flex-row-reverse items-center gap-x-[5px]'>
            <button className='rounded-full w-[30px] h-[30px] bg-[#E7E7E74D] bg-opacity-50 flex items-center justify-center'>
              <img src={MessageIcon} alt='' />
            </button>
            <button className='rounded-full w-[30px] h-[30px] bg-[#E7E7E74D] bg-opacity-50 flex items-center justify-center'>
              <img src={PhoneCallIcon} alt='' />
            </button>
          </div>
        </div>
        <div className='flex items-center justify-between'>
          <Status status={visitor.status} />
          <p className='text-[14px] text-doiteasy-black -mt-[20px]'>{visitor.timeIn} - {visitor.timeOut}</p>
        </div>
    </div>
  )
}

export default VisitorCard