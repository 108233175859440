import React, { useState } from 'react'
import Mailbox from '../../assets/images/icons/mailbox.svg'

import axios from 'axios'
import { useDispatch } from 'react-redux'
import { ERROR } from '../../store/types'
import OTPInput from '../elements/form/OtpInput'
import FormButton from '../elements/form/FormButton'
import Countdown from '../elements/Countdown'

const EmailConfirmation = ({email, closeModalOnSuccess}) => {
    const dispatch = useDispatch()
    const [confirmingEmail, setConfirmingEmail] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(true);
    const [counted, setCounted] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const confirmEmail = async () => {
        try {
            if(!otp || otp === '') {
                setValidationErrors({otp: true})
                return
            }
            const headers = {
                'Content-Type': 'application/json'
            }
            const requestPayload = { 
                confirmationCode: otp
            }
            setConfirmingEmail(true)
            
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/email-confirmation`, requestPayload, { headers }) 
            localStorage.setItem("authToken", JSON.stringify(response.data.data.data.accessToken));

            setConfirmingEmail(false)
            // setConfirmationModalOpen(false)
            closeModalOnSuccess()
            // setActiveStep(1)
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            // setProcessing(false)
        }
    }

    const resendOtp = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json'
            }
            const requestPayload = { 
                email: email,
            }
            
            await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/email-confirmation/resend`, requestPayload, { headers }) 
            // setProcessing(false)
            setOtpSent(true)
            setCounted(false)
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            // setProcessing(false)
        }
    }

    return (
        <div className='w-full'>
            <img src={Mailbox} alt='' className='w-[50px] h-[50px] mx-auto' />
            <div className='w-full text-center py-[10px] border-b'>
                <h3 className='mt-[10px] text-[24px] font-space-grotesk text-doiteasy-black font-[550]'>Check your email</h3>
                <p className='text-sm text-gray-500'>We have sent an otp to your email address ({email}). Please provide it below</p>
            </div>

            <div className='w-max mx-auto mt-[20px]'>
                <OTPInput length={6} onChange={(value)=>{setOtp(value)}} hasError={validationErrors && validationErrors.otp} />
            </div>
            {counted && !otpSent 
                ? 
                <button 
                    className='mt-3 font-medium w-full block bg-opacity-10 text-doiteasy-blue transition duration-200 hover:text-blue-700' 
                    onClick={()=>{resendOtp()}}
                >
                    Resend confirmation email
                </button>
                :
                <>
                    <p className='py-5 mt-2 text-gray-400 text-center'>Didn&apos;t get the otp? please wait <Countdown seconds={60} className='inline text-blue-800 font-bold' countdownComplete={()=>{setCounted(true)}} /> seconds
                    </p>
                </>
            }

            <div className='mt-4 w-[70%] mx-auto'>
                <FormButton 
                    buttonLabel={`Confirm Email`} 
                    buttonAction={()=>{confirmEmail()}} 
                    processing={confirmingEmail} />
            </div>


        </div>
    )
}

export default EmailConfirmation