import { CLASSES_ERROR, GETTING_CLASSES, GET_CLASSES, UPDATE_CLASS, UPDATING_CLASS } from "../types"

const initialState = {
    classes: [],
    loadingClasses: false,
    classesError: null,
    updatingClass: false,
    updatedClass: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_CLASSES:
        return {
            ...state,
            loadingClasses:action.payload,
            // fetchingMembers:false
        }
        case GET_CLASSES:
        return{
            ...state,
            loadingDepartments:false,
            departmentsError: false,
            classes: action.payload,
        }
        case UPDATING_CLASS:
        return {
            ...state,
            updatingClass:action.payload,
        }
        case UPDATE_CLASS:
        return{
            ...state,
            updatingClass:false,
            classesError: null,
            updatedClass: action.payload,
        }
        case CLASSES_ERROR:
        return{
            ...state,
            loadingClasses:false,
            classes: null,
            classesError: action.payload 
        }
        default: return state
    }

}